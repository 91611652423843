if ($('#worldmap').length) {

	var mapRef = Snap('#worldmap');
	var dot = mapRef.select('#theDot');

	var theWorldMap = {

		runMap: false,

		circle: $('#theDot circle'),

		dotbbox: dot.getBBox(),

		mapAnimations: [],

		i: 0,

		worldmapStart: function() {
			console.log('worldmap start');
			theWorldMap.runMap = true;
			theWorldMap.circle.removeClass('dot-hidden');
			theWorldMap.i = 0;
			theWorldMap.animateDot(theWorldMap.mapAnimations[theWorldMap.i]);
		},

		animateDot: function (a) {
			console.log('animate');
			Snap.animate(0, a.path_length, function( step ) {
				moveToPoint = Snap.path.getPointAtLength( a.SVGpath, step );
				dot.transform('translate(' + moveToPoint.x + ',' + moveToPoint.y + ') rotate('+ (moveToPoint.alpha - 90)+', ' + theWorldMap.dotbbox.cx + ', ' + theWorldMap.dotbbox.cy + ')');
			},2000, mina.easeinout, function() {
				theWorldMap.i++;
				if (theWorldMap.i == theWorldMap.mapAnimations.length && theWorldMap.runMap) {
					theWorldMap.i = 0;
				}
				if (theWorldMap.runMap) {
					theWorldMap.animateDot(theWorldMap.mapAnimations[theWorldMap.i]);
				}
			});
		},

		worldmapCheck: function () {
			if ($('.swiper-slide-active').hasClass('worldmap')) {
				$('#banner').addClass('white-arrows');
			} else {
				$('#banner').removeClass('white-arrows');
			}
		}
	}

	var mapPaths = $('g#locations > path').map(function(){
		return $(this).attr('d');
	});
	mapPaths = jQuery.makeArray(mapPaths);
	mapPaths.forEach(function(element) {
		var SVGpath = mapRef.path(element).attr({ 'fill': 'none', 'stroke': 'none'});
		var path_length = Snap.path.getTotalLength(SVGpath);
		var last_point = SVGpath.getPointAtLength(path_length);
		theWorldMap.mapAnimations.push({
			SVGpath: SVGpath,
			path_length: path_length,
			last_point: last_point
		});
	}, this);
	theWorldMap.worldmapStart();

}


$(function () {

	$(document).on('click', '.compact-tablist button', function (e) {
		console.log('cl');
		e.preventDefault();
		var id = $(this).attr('aria-controls');
		$(this).attr('aria-selected', "true");
		$("#" + id).attr('aria-hidden', "false");
		
		$(this).closest('.compact-tablist').find('button').not(this).each(function(){
			$(this).attr('aria-selected', "false");
			var id = $(this).attr('aria-controls');
			$("#" + id).attr('aria-hidden', "true");
		});
	});
	$(document).on('click', '.accordion-item button', function (e) {
		e.preventDefault();
		var id = $(this).data('id');
		$('.accordion-item button[aria-selected="true"][data-id="'+id+'"]').not($(this)).each(function(){
			$(this).attr('aria-selected', "false");
			$(this).next('div[aria-hidden]').attr('aria-hidden', "true");
		});
		var isSelected = $(this).attr('aria-selected') == "true";
		$(this).attr('aria-selected', (!isSelected).toString().toLowerCase())
		$(this).next('div[aria-hidden]').attr('aria-hidden', isSelected.toString().toLowerCase());
	});

	// nav
	$('.expand-nav').on('click', function (e) {
		$('.mobile-nav-wrap').fadeToggle(100);
		$('body').toggleClass('nav-expanded');
		if ($('.overlay').length) {
			$('.overlay').fadeOut(200, function () {
				$(this).remove();
			});
		} else {
			var overlay = $('<div class="overlay"></div>');
			overlay.appendTo('body').height($(document).height()).fadeIn(100);
		}
		e.preventDefault();
	});

	$(document).on('click', '.overlay', function () {
		$('.mobile-nav-wrap').slideUp('300');
		$('.overlay').remove();
		$('body').removeClass('nav-expanded');
	});

	$(document).on('click', '.subnav-expand', function (e) {
		e.preventDefault();
		if ($(window).width() < 768) {
			$(this).siblings('ul').slideToggle(200);
		}
	});

	var initialWidth = $(window).width();
	$(window).on('resize', function() {
		if ($(window).width() != initialWidth) {
			$('body').removeClass('nav-expanded');
			$('.overlay').remove();
			if ($(window).width() > 767) {
				$('.mobile-nav-wrap').show();
				$('header nav.main li ul').css('display','none');
			} else {
				$('.mobile-nav-wrap').hide();
			}
		}
	});

	// wrap videos in responsive box
	$("main section.content iframe").wrap('<div class="embed-wrap"></div>');

	// home banner carousel
	if ($('#banner').length) {
		var bannerSwiper = new Swiper('#banner', {
			direction: 'horizontal',
			autoplay: 7000,
			autoHeight: true,
			speed: 900,
			a11y: true,
			pagination: '.swiper-pagination',
			paginationType: 'bullets',
			paginationClickable: true,
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev',
			loop: true,
			onInit: function() {
				if ($('#worldmap').length) {
					theWorldMap.worldmapCheck();
				}
			}
		});
		bannerSwiper.on('slideChangeEnd', function () {
			if ($('#worldmap').length) {
	    	theWorldMap.worldmapCheck();
			}
		});
	}
	if ($('#home-clients').length) {
		var clientsSwiper = new Swiper('#home-clients', {
			direction: 'horizontal',
			loop: true,
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev'
		});
	}

	var banner = document.getElementById('banner');
	var bannerVideo = document.getElementById('banner-video');
	if (bannerVideo) {
		var player = new Vimeo.Player(bannerVideo);
		player.on('play', function() {
			bannerSwiper.stopAutoplay();
			$(banner).addClass('video-playing');
		});
		player.on('pause', function() {
			bannerSwiper.startAutoplay();
			$(banner).removeClass('video-playing');
		});
		player.on('ended', function() {
			bannerSwiper.startAutoplay();
			$(banner).removeClass('video-playing');
		});
	}

	// team filter
	if ($('#team-select').length) {
		memberMap = $('section.location-members .member').map(function() {
			return $(this).data('id');
		});
		memberMap = jQuery.makeArray(memberMap);
		memberHTML = $('section.location-members .member').map(function() {
			return this.outerHTML;
		});
		memberHTML = jQuery.makeArray(memberHTML);
		$('#team-select').on('change', function() {
			teamContainer = $('section.location-members .wrap');
			teamContainer.empty();
			if ($('#team-select :selected').val() === 'All') {
				console.log('All');
				for (var i = 0; i < memberHTML.length; i++) {
					teamContainer.append(memberHTML[i]);
				}
			} else {
				var showMembersString = $('#team-select :selected').data('ids') + '';
				var showMembers = showMembersString.split(',');
				showMembersKeys = [];
				showMembers.forEach(function(e) {
					showMembersKeys.push(memberMap.indexOf(parseInt(e)));
				}, this);
				showMembersKeys.forEach(function(e) {
					teamContainer.append(memberHTML[e]);
				}, this);
			}
		});
	}

	// validate contact form
	if ($("#contact-form").length) {
		$("#contact-form").validate();
		$("#other-interest").on('focus', function() {
			$('#interests input').prop('checked',false);
			$('#int-other').prop('checked',true);
		});
	}

	// location accordion on mobile
	$('.mobile-location-list li > a').on('click', function(e){
		$(this).toggleClass('active');
		$(this).parent('li').find('.inner').slideToggle();
		e.preventDefault();
	});

	$('.mobile-location-list .btn').on('click', function(e){
		$('#branch option:selected').prop("selected",null);
		$('#branch option[value="' + $(this).attr('data-title') + '"]').prop("selected","selected");
	});

	// closest office click to call
	if ($('#click-to-call').length || $('#branch').length) {
		var cookieLocation = Cookies.get('kp_location');
		if (typeof cookieLocation === "undefined" ) {
			geoLocate();
		} else {
			var closest = JSON.parse(cookieLocation);
			updateClicktoCall(closest);
		}
	}


    $('#form-input-datePicker').datetimepicker({
        dayOfWeekStart : 1,
        format:'d.m.Y  H:i',
        lang:'en',
        disabledDates:['1986/01/08','1986/01/09','1986/01/10'],
        startDate:	'2017/01/01',
        onGenerate:function( ct ){
            $(this).find('.xdsoft_date.xdsoft_weekend')
                .addClass('xdsoft_disabled');
        },
        weekends:['01.01.2014','02.01.2014','03.01.2014','04.01.2014','05.01.2014','06.01.2014'],
        timepicker:false
    });


	// Statistics
    $('.stat strong').counterUp({
        delay: 10, // the delay time in ms
    	time: 1000 // the speed time in ms
	});


    // if ($('.form-pages form').length){
	// 	$('.form-pages form').submit( function (e ) {
	// 		e.preventDefault();
    //
	// 		$('body').addClass('overlay-active');
     //        $("html, body").animate({ scrollTop: "0px" });
    //
	// 		var data = $(this).serialize();
	// 		$.ajax({
	// 			method: 'POST',
	// 			url: '/',
	// 			data: data,
	// 			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	// 		})
	// 			.success(function(data) {
	// 				if ($('.overlay-wrapper').length){
	// 					$('.overlay-wrapper').removeClass('hide');
	// 				}
	// 			})
	// 			.error(function(data) {
	// 				console.log('error', data);
	// 			});
	// 	});
    // }
    //
    // $('.close-thik').on('click touch', function(){
    //
     //    $('.overlay-wrapper').addClass('hide');
     //    $('body').removeClass('overlay-active');
	// })


});