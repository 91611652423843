// // Avoid `console` errors in browsers that lack a console.
// (function() {
// 		var method;
// 		var noop = function () {};
// 		var methods = [
// 				'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
// 				'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
// 				'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
// 				'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
// 		];
// 		var length = methods.length;
// 		var console = (window.console = window.console || {});
//
// 		while (length--) {
// 				method = methods[length];
//
// 				// Only stub undefined methods.
// 				if (!console[method]) {
// 						console[method] = noop;
// 				}
// 		}
// }());
//
// // Analytics
// (function(b,o,i,l,e,r){b.GoogleAnalyticsObject=l;b[l]||(b[l]=
// function(){(b[l].q=b[l].q||[]).push(arguments)});b[l].l=+new Date;
// e=o.createElement(i);r=o.getElementsByTagName(i)[0];
// e.src='https://www.google-analytics.com/analytics.js';
// r.parentNode.insertBefore(e,r)}(window,document,'script','ga'));
// ga('create','UA-XXXXX-X','auto');ga('send','pageview');

// smooth scroll to anchored link
$('a[href*="#"]:not([href="#"])').click(function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);
			return false;
		}
	}
});


// geolocation for closest office
Number.prototype.toRad = function() {
   return this * Math.PI / 180;
}

function distance(position1,position2){
	var lat1=position1.latitude;
	var lat2=position2.latitude;
	var lon1=position1.longitude;
	var lon2=position2.longitude;
	var R = 6371000; // metres
	var φ1 = lat1.toRad();
	var φ2 = lat2.toRad();
	var Δφ = (lat2-lat1).toRad();
	var Δλ = (lon2-lon1).toRad();

	var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
			Math.cos(φ1) * Math.cos(φ2) *
			Math.sin(Δλ/2) * Math.sin(Δλ/2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

	var d = R * c;
	return d;
}

function geoLocate() {
  if (!navigator.geolocation){
    return;
  }
  function success(position) {
		findClosest(position);
  };
  function error() {
  };
  navigator.geolocation.getCurrentPosition(success, error);
}

function findClosest(position) {
	$.getJSON( "/locations.json", function( data ) {
		var closest = data.locations[0];
		var closest_distance = distance(closest, position.coords);
		for(var i=1; i < data.locations.length; i++){
			if(distance(data.locations[i], position.coords) < closest_distance){
				closest_distance = distance(data.locations[i], position.coords);
				closest = data.locations[i];
			}
		}
		Cookies.set('kp_location', JSON.stringify(closest), { expires: 1 });
		updateClicktoCall(closest);
	}).fail(function() {
		console.log( "error" );
	});
}

function updateClicktoCall(closest) {
	$('#click-to-call').attr('href', 'tel:' + closest.phone);
	if ($('#branch').length) {
		$('#branch option[value="' + closest.title + '"]').prop('selected', true);
	}
}